<template>
  <SiteNav />
</template>

<script>
import SiteNav from './components/SiteNav.vue';

export default {
  name: 'App',
  components: {
    SiteNav
  }
}
</script>

<style lang="scss">
#app {
  background-color: $background;
}
</style>
